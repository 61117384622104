import { serverOnly$ } from "vite-env-only/macros";

import deTranslation from "~/locales/de";

export const supportedLngs = ["de"];

export const fallbackLng = "de";

export const resources = serverOnly$({
	de: deTranslation,
});
