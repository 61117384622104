import { createTheme } from "@mui/material";

import { colors } from "~/styles/colors";

export const theme = createTheme({
	typography: {
		fontFamily: ["Source Sans Pro", "Roboto", "Arial"].join(","),
		h1: {
			fontWeight: 600,
			fontSize: 36,
			"@media (min-width:600px)": {
				fontSize: 48,
			},
		},
		h2: {
			fontWeight: 600,
			fontSize: 32,
		},
		h3: {
			fontWeight: 600,
			fontSize: 28,
			"@media (min-width:600px)": {
				fontSize: 24,
			},
		},
		h4: {
			fontWeight: 600,
			fontSize: 24,
			"@media (min-width:600px)": {
				fontSize: 20,
			},
		},
		h5: {
			fontWeight: 600,
			fontSize: 20,
			"@media (min-width:600px)": {
				fontSize: 18,
			},
		},
		h6: {
			fontWeight: 600,
			fontSize: 28,
			"@media (min-width:600px)": {
				fontSize: 16,
			},
		},
		subtitle1: {
			fontWeight: 400,
			fontSize: 16,
		},
		subtitle2: {
			fontWeight: 400,
			fontSize: 16,
		},
		body1: {
			fontWeight: 400,
			fontSize: 16,
		},
		body2: {
			fontWeight: 400,
			fontSize: 16,
		},
		button: {
			fontWeight: 400,
			fontSize: 16,
			textTransform: "none",
		},
		caption: {
			fontWeight: 400,
			fontSize: 14,
		},
		overline: {
			fontWeight: 400,
			fontSize: 16,
		},
	},
	components: {
		MuiLinearProgress: {
			styleOverrides: {
				bar: {
					backgroundColor: colors.brand3,
				},
			},
		},
		MuiDialogTitle: {
			styleOverrides: {
				root: {
					lineHeight: 1.2,
				},
			},
		},

		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontSize: 14,
					lineHeight: 1.1,
				},
			},
		},
		MuiToggleButton: {
			styleOverrides: {
				root: {
					borderColor: colors.gray4,
					color: colors.gray1,
					margin: "3px",
					"&:hover": {
						background: colors.gray,
						color: colors.white,
					},
					"&.Mui-selected": {
						fontWeight: 600,
						background: colors.gray6,
						color: colors.white,
					},
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				colorPrimary: {
					"&.Mui-checked": {
						color: colors.brand,
					},
					"&.Mui-checked + .MuiSwitch-track": {
						backgroundColor: colors.brand,
					},
				},
			},
		},
		MuiCircularProgress: {
			styleOverrides: {
				colorPrimary: {
					color: colors.brand,
				},
			},
		},
		MuiSlider: {
			styleOverrides: {
				rail: {
					background: `linear-gradient(0deg, ${colors.white} 0%, ${colors.gray} 100%) !important`,
					opacity: 0.9,
					width: 7,
				},
				thumb: {
					width: 25,
					height: 25,
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					overflowX: "hidden",
					textOverflow: "ellipsis",
				},
				root: {
					"&.Mui-readOnly": {
						pointerEvents: "none",
						userSelect: "none",
						color: colors.gray2,
						"&.MuiFilledInput-underline": {
							"&::before": {
								borderBottomStyle: "dotted",
							},
						},
					},
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					height: 26,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					backgroundColor: colors.gray7,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderColor: colors.gray4,
				},
				head: {
					fontWeight: 600,
					color: colors.gray1,
					borderBottomWidth: 2,
					fontSize: 14,
					textTransform: "uppercase",
					backgroundColor: `${colors.gray6}EE`,
					borderRadius: 0,
					webkitBackdropFilter: "blur(7px)",
					backdropFilter: "blur(7px)",
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					backgroundColor: `${colors.gray9}66`,
					borderRadius: 4,
					webkitBackdropFilter: "blur(6px)",
					backdropFilter: "blur(6px)",
				},
			},
		},
		MuiBottomNavigationAction: {
			styleOverrides: {
				root: {
					"&.Mui-selected": {
						fontWeight: 600,
					},
				},
			},
		},
		MuiBackdrop: {
			styleOverrides: {
				root: {
					webkitBackdropFilter: "blur(10px)",
					backdropFilter: "blur(10px)",
				},
			},
		},
		MuiDialog: {
			styleOverrides: {
				paper: {
					margin: 10,
					width: "100%",
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: `${colors.gray7}99`,
					borderRadius: 4,
					webkitBackdropFilter: "blur(7px)",
					backdropFilter: "blur(7px)",
					backgroundImage: "none",
				},
			},
		},
		MuiRadio: {
			styleOverrides: {
				root: {
					marginRight: -2,
				},
			},
		},

		MuiFormLabel: {
			styleOverrides: {
				asterisk: {
					fontWeight: 600,
					color: colors.red3,
					paddingTop: 1,
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				primary: {
					backgroundColor: colors.brand,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textOverflow: "ellipsis",
					overflow: "hidden",
					whiteSpace: "nowrap",
				},
				containedPrimary: {
					backgroundColor: colors.brand,
				},
				outlinedPrimary: {
					borderColor: colors.brand,
					color: colors.brand,
					":hover": {
						borderColor: colors.brand3,
						color: colors.brand3,
					},
				},
			},
		},
	},
	palette: {
		mode: "dark",
		primary: {
			main: colors.white,
		},
		secondary: {
			main: colors.gray1,
		},
		error: {
			main: colors.red3,
		},
		warning: {
			main: colors.yellow,
		},
		info: {
			main: colors.blue,
		},
		success: {
			main: colors.green4,
		},
	},
});
